<template>
  <div class="lamano-contactform-container">
    <div class="lamano-contactform-flex">
      <h3 class="lamano-contact-title">III. {{ categorytext3 }}</h3>
      <div class="lamano-contact-paragraph">
        {{ maintext4 }}
      </div>
    </div>
    <div class="lamano-contactform-flex" :class="{ unvisible: contactformunvisible }">
      <input type="text" class="lamano-contact-input-text" :class="{ wrong: infomissingname }" v-model="name" :placeholder="contacttext1" />
      <input
        type="text"
        class="lamano-contact-input-text"
        :class="{ wrong: infomissingemail }"
        v-model="email"
        :placeholder="contacttext2"
      />
      <input
        type="text"
        class="lamano-contact-input-text"
        :class="{ wrong: infomissingphone }"
        v-model="phone"
        :placeholder="contacttext6"
      />
      <input
        type="text"
        class="lamano-contact-input-text"
        :class="{ wrong: infomissingsubject }"
        v-model="subject"
        :placeholder="contacttext3"
      />
      <textarea
        class="lamano-contact-input-textarea"
        :class="{ wrong: infomissingtext }"
        v-model="text"
        :placeholder="contacttext4"
      ></textarea>
      <button class="lamnao-contact-input-button" v-on:click="validateForm()">
        {{ contacttext5 }}
      </button>
    </div>
    <div class="lamano-contactform-flex" :class="{ unvisible: !contactformunvisible }">
      <h2 class="lamano-contactform-confirm">{{ contactformconfirm }}</h2>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import { getCMSText } from "@/lib/TextGetter.js";

export default {
  name: "ContactForm",
  setup() {},
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      subject: "",
      text: "",
      maintext4: "",
      categorytext3: "",
      contacttext1: "",
      contacttext2: "",
      contacttext3: "",
      contacttext4: "",
      contacttext5: "",
      contacttext6: "",
      contactformconfirm: "",
      infomissingname: false,
      infomissingemail: false,
      infomissingphone: false,
      infomissingsubject: false,
      infomissingtext: false,
      contactformunvisible: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },
  async mounted() {
    this.categorytext3 = await getCMSText("categorytext3");
    this.maintext4 = await getCMSText("maintext4");

    this.contacttext1 = (await getCMSText("contacttext1")) + "*";
    this.contacttext2 = (await getCMSText("contacttext2")) + "*";
    this.contacttext3 = (await getCMSText("contacttext3")) + "*";
    this.contacttext4 = (await getCMSText("contacttext4")) + "*";
    this.contacttext5 = await getCMSText("contacttext5");
    this.contacttext6 = await getCMSText("contacttext6");
    this.contactformconfirm = await getCMSText("contactformconfirm");
  },
  methods: {
    validateForm() {
      var data = [this.name, this.email, this.subject, this.text];
      let everythingValid = true;
      for (let i = 0; i < data.length; i++) {
        let dataItem = data[i];
        if (dataItem == "") {
          everythingValid = false;
        }
      }

      if (!this.reg.test(this.email)) {
        console.log("false email");
        everythingValid = false;
      }

      if (everythingValid == true) {
        this.infomissingname = false;
        this.infomissingemail = false;
        this.infomissingphone = false;
        this.infomissingsubject = false;
        this.infomissingtext = false;

        console.log("send mail");
        this.contactformunvisible = true;
        this.$recaptchaLoaded().then(() => {
          this.$recaptcha("login").then(token => {
            const payload = {
              name: this.name,
              email: this.email,
              phone: this.phone,
              subject: this.subject,
              text: this.text
            };

            var result = emailjs.send("service_fnbne7l", "template_5eyt0ok", payload, "user_JuNjewNde6j2lpBy0VAek");
            alert("Your message has been send");
            window.location.reload();
          });
        });
      } else {
        if (this.name == "") {
          this.infomissingname = true;
        } else {
          this.infomissingname = false;
        }

        if (this.email == "") {
          this.infomissingemail = true;
        } else {
          if (!this.reg.test(this.email)) {
            this.infomissingemail = true;
          } else {
            this.infomissingemail = false;
          }
        }

        if (this.subject == "") {
          this.infomissingsubject = true;
        } else {
          this.infomissingsubject = false;
        }

        if (this.text == "") {
          this.infomissingtext = true;
        } else {
          this.infomissingtext = false;
        }
      }
    }
  }
};
</script>

<style>
.lamano-contactform-container {
  min-height: 100vh;
  width: 90%;
  display: flex;

  align-items: center;
  justify-content: center;
}

.lamano-contactform-flex {
  flex: 1;
  width: 100%;

  padding: 2em;

  display: flex;
  flex-direction: column;
}

.lamano-contact-title {
  color: var(--color-gold-1);

  font-size: 32pt;
  font-weight: 300;

  font-family: "Lora", sans-serif;

  margin: 0 0 0 0;
  padding: 0;
  text-align: left;
}

.lamano-contact-subtitle {
  color: var(--color-grey-3);

  font-size: 18pt;
  font-weight: 300;

  font-family: "Lora", sans-serif;

  margin: 0;
  padding: 0;
}

.lamano-contact-paragraph {
  color: var(--color-grey-3);

  font-size: 12pt;
  font-weight: 400;

  font-family: "Montserrat", sans-serif;

  margin: 1em 0 0 0;
  padding: 0;

  width: 70%;
}

.lamano-contact-input-text {
  height: 1em;

  background-color: var(--color-grey-2);
  border: 1px solid var(--color-grey-3);

  padding: 1em;

  font-size: 11pt;
  font-weight: 400;

  font-family: "Montserrat", sans-serif;

  margin-bottom: 1em;
  color: white;
}

.lamano-contact-input-text:focus {
  outline: 2px solid var(--color-gold-1);
}

.lamano-contact-input-text.wrong {
  outline: 1px solid red !important;
  border: 1px solid red;
}

.lamano-contact-input-textarea {
  background-color: var(--color-grey-2);
  border: 1px solid var(--color-grey-3);

  padding: 1em;

  font-size: 12pt;
  font-weight: 400;

  font-family: "Montserrat", sans-serif;

  margin-bottom: 1em;
  color: white;

  min-height: 5em;
}

.lamano-contact-input-textarea:focus {
  outline: 2px solid var(--color-gold-1);
}

.lamano-contact-input-textarea.wrong {
  outline: 1px solid red !important;
  border: 1px solid red;
}

.lamnao-contact-input-button {
  background-color: var(--color-gold-1);
  border: 1px solid var(--color-grey-3);

  padding: 1em;

  font-size: 12pt;
  font-weight: 400;

  font-family: "Montserrat", sans-serif;

  margin-bottom: 0em;
  color: var(--color-grey-2);
}
.lamnao-contact-input-button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .lamnao-contact-input-button {
    width: 100%;
    margin-bottom: 4em;
  }

  .lamano-contact-input-text {
    width: 92%;
  }

  .lamano-contact-input-textarea {
    width: 92%;
  }

  .lamano-contactform-flex {
    width: 100%;
  }
}

.lamano-contactform-flex.unvisible {
  display: none;
}

.lamano-contactform-confirm {
  color: var(--color-gold-1);

  font-size: 24pt;
  font-weight: 400;

  font-family: "Lora", sans-serif;
}
</style>
