<template>
  <div class="lamano-navigation-container" :class="{ folded: isActive }">
    <img src="@/assets/icons/menu_black_24dp.svg" class="lamano-nav-menu" v-on:click="toggleSidebar" />
    <Transition>
      <div class="lamano-navigation-fade" v-if="!isActive || !isMobile">
        <div class="lamano-navigation-part top" :class="{ folded: isActive }">
          <img src="@/assets/logo-gold.svg" class="lamano-nav-logo" />
          <h2 class="lamano-nav-title" :class="{ folded: isActive }">
            Yann Lepinois
          </h2>
          <h2 class="lamano-nav-subtitle" :class="{ folded: isActive }">
            Goldsmith
          </h2>

          <img src="@/assets/chevron.svg" class="lamano-nav-chevron" v-on:click="toggleSidebar"
            :class="{ folded: isActive }" />
        </div>

        <div class="lamano-navigation-part middle" :class="{ folded: isActive }">
          <div class="lamano-navigation-part-selector" @click="scroll('lamano-home-portfolio')">
            <h2 class="lamano-navigation-part-selector-chapter">I</h2>
            <h2 class="lamano-navigation-part-selector-chapter-title" :class="{ folded: isActive }">
              {{ titleText2 }}
            </h2>
          </div>
          <div class="lamano-navigation-part-selector" @click="scroll('lamano-home-introduction')">
            <h2 class="lamano-navigation-part-selector-chapter">II</h2>
            <h2 class="lamano-navigation-part-selector-chapter-title" :class="{ folded: isActive }">
              {{ titleText3 }}
            </h2>
          </div>
          <div class="lamano-navigation-part-selector" @click="openInNewTab('https://lamanoshop.com')">
            <h2 class="lamano-navigation-part-selector-chapter">III</h2>
            <h2 class="lamano-navigation-part-selector-chapter-title" :class="{ folded: isActive }">
              {{ titleText5 }}
            </h2>
          </div>
          <div class="lamano-navigation-part-selector" @click="scroll('lamano-home-contact')">
            <h2 class="lamano-navigation-part-selector-chapter">IV</h2>
            <h2 class="lamano-navigation-part-selector-chapter-title" :class="{ folded: isActive }">
              {{ titleText4 }}
            </h2>
          </div>
        </div>

        <img src="@/assets/madeinlux.png" class="lamano-madeinlux" />

        <div class="lamano-navigation-part down" :class="{ folded: isActive }">
          <a href="https://www.instagram.com/la_mano_jewellery" target="_blank"><img src="@/assets/instagram.svg"
              class="lamano-nav-social-icon" /></a>
          <a class="lamano-navigation-language-a" href="/en/">EN</a>
          <a class="lamano-navigation-language-a" href="/fr/">FR</a>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { getCMSText } from "@/lib/TextGetter.js";

export default {
  name: "NavigationBar",
  data() {
    return {
      isActive: false,
      isMobile: false,
      titleText2: "",
      titleText3: "",
      titleText4: "",
      titleText5: ""
    };
  },
  methods: {
    toggleSidebar() {
      this.isActive = !this.isActive;
    },

    openInNewTab(url) {
      window.open(url, "_blank", "noreferrer");
    },
    scroll(id) {
      var innerWidth = window.innerWidth;
      var isMobile = innerWidth < 600;

      if (window.matchMedia("(orientation: portrait)").matches) {
        isMobile = true;
      }

      if (isMobile) {
        this.toggleSidebar();
        setTimeout(function() {
          document.getElementById(id).scrollIntoView({
            behavior: "smooth"
          });
        }, 50);
      } else {
        document.getElementById(id).scrollIntoView({
          behavior: "smooth"
        });
      }
    }
  },
  async mounted() {
    this.titleText2 = await getCMSText("categorytext1");
    this.titleText3 = await getCMSText("categorytext2");
    this.titleText4 = await getCMSText("categorytext3");
    this.titleText5 = await getCMSText("categorytext4");

    this.loading = false;

    if (window.innerWidth < 600) {
      this.isMobile = true;
    }
  }
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.lamano-navigation-container {
  width: 20vw;
  height: 100%;

  background-color: var(--color-grey-1);

  display: flex;

  flex-direction: column;
  align-items: center;

  transition: width 500ms;
}

.lamano-navigation-fade {
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: column;
  align-items: center;
}

.lamano-navigation-container.folded {
  width: 6em;
}

@media only screen and (max-width: 600px),
(orientation: portrait) {
  .lamano-navigation-container {
    width: 99.9vw;
  }

  .lamano-navigation-container.folded {
    width: 0vw;
  }
}

.lamano-nav-logo {
  width: 4em;

  margin: 4em 0 2em 0;
}

.lamano-nav-title {
  margin: 0;
  padding: 0;

  color: var(--color-grey-3);

  font-family: "Montserrat", sans-serif;
  font-size: 14pt;
  font-weight: 500;
  text-align: center;
  transition: opacity 200ms;

  white-space: nowrap;
}

.lamano-nav-subtitle {
  margin: 0;
  padding: 0;

  color: var(--color-grey-3);

  font-family: "Montserrat", sans-serif;
  font-size: 14pt;
  font-weight: 300;

  text-align: center;
  transition: opacity 200ms;

  white-space: nowrap;
}

.lamano-navigation-part.top {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.lamano-navigation-part.middle {
  flex-grow: 1;
  height: 4em;

  display: flex;

  flex-direction: column;
  width: 100%;

  padding: 2em 0 2em 0;
}

.lamano-navigation-part.down {
  height: 4em;

  display: flex;
  align-items: center;
}

.lamano-nav-social-icon {
  width: 1.5em;
}

.lamano-madeinlux {
  width: 3.75em;
}

.lamano-navigation-part-selector {
  flex: 1;

  margin: 0.3em 0.6em 0.3em 0.6em;

  background-color: var(--color-grey-2);

  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: background-color 200ms;
}

.lamano-navigation-part-selector:hover {
  cursor: pointer;
  background-color: var(--color-grey-5);
}

.lamano-navigation-part-selector-chapter {
  margin: 0;
  padding: 0;
  color: var(--color-grey-3);

  font-family: "Lora", serif;
  font-size: 22pt;
  font-weight: 300;
  text-transform: uppercase;

  white-space: nowrap;
}

.lamano-navigation-part-selector-chapter-title {
  margin: 0;
  padding: 0;

  color: var(--color-grey-3);

  font-family: "Montserrat", serif;
  font-size: 11pt;
  font-weight: 400;
  text-transform: uppercase;
  transition: opacity 200ms;

  white-space: nowrap;
}

.lamano-nav-chevron {
  margin: 2em;
  transition: transform 250ms;

  transform: rotateZ(180deg);
}

.lamano-nav-chevron:hover {
  cursor: pointer;
}

.lamano-nav-chevron.folded {
  transform: rotateZ(0deg);
}

.lamano-nav-menu {
  display: none;
}

@media only screen and (max-width: 600px),
(orientation: portrait) {
  .lamano-nav-menu {
    position: fixed;

    z-index: 1000;

    top: 5vw;
    right: 5vw;

    width: 10vw;

    margin: 0em;
    display: block;
  }

  .lamano-nav-chevron {
    display: none;
  }

  .lamano-nav-chevron.folded {
    transform: rotateZ(90deg);
  }

  .lamano-nav-logo {
    margin: 2em 0 1em 0;
  }

  .lamano-madeinlux {
    width: 3.75em;
  }
}

.lamano-navigation-part-selector-chapter-title.folded {
  opacity: 0;
}

.lamano-nav-title.folded {
  opacity: 0;
}

.lamano-nav-subtitle.folded {
  opacity: 0;
}

.lamano-navigation-part.middle.folded .lamano-navigation-part-selector {
  background-color: var(--color-grey-1);
  margin: 0.3em 0 0.3em 0;
}

.lamano-navigation-part.middle.folded .lamano-navigation-part-selector:hover {
  background-color: var(--color-grey-2);
}

.lamano-navigation-language-a {
  text-decoration: none;
  color: var(--color-grey-3);

  font-family: "Montserrat", serif;
  font-size: 12pt;
  font-weight: 300;

  margin: 0;
  padding: 0 0 0 0.4em;
}</style>
