import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { VueReCaptcha } from "vue-recaptcha-v3";

import axios from "axios";
import VueAxios from "vue-axios";

import "animate.css";

let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, "$1");

if (window.location.pathname == "" || window.location.pathname == "/" || (locale != "lu" && locale != "en" && locale != "fr")) {
  window.location.href = "/en/";
}

createApp(App)
  .use(router)
  .use(VueAxios, axios)
  .use(VueReCaptcha, {
    siteKey: "6LePNlkeAAAAALyWxH5zGIs2ltVz1u4V-252Q0he",
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
    }
  })
  .mount("#app");
