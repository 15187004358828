<template>
  <div class="lamano-gallery-container">
    <div class="lamano-gallery-filter-section" v-if="!imageFullscreen">
      <h2 class="lamano-gallery-title">I. {{ categorytext1 }}</h2>
      <ul class="lamano-gallery-filter-list">
        <li :class="{ selected: selectedFilter == 0 }" v-on:click="selectFilter(0)">
          {{ allCategoriesText }}
        </li>
        <li
          v-for="category in allCategories"
          v-bind:key="category.id"
          :class="{ selected: selectedFilter == category.id }"
          v-on:click="selectFilter(category.id)"
        >
          {{ category.categoryName }}
        </li>

        <h2>Discover more</h2>
        <ul class="pagination-ul">
          <li
            v-on:click="
              if (currentPage > 0) {
                currentPage--;

                fullscreenoverlay = false;
                selectedImagePath = '';
                getAllPhotos();
              }
            "
          >
            &lt;
          </li>
          <li>{{ currentPage + 1 + "-" + Math.ceil(countImages / maxImages) }}</li>
          <li
            v-on:click="
              if (currentPage < countImages / maxImages - 1) {
                currentPage++;

                fullscreenoverlay = false;
                selectedImagePath = '';
                getAllPhotos();
              }
            "
          >
            &gt;
          </li>
        </ul>
      </ul>
    </div>

    <div class="lamano-gallery-portfolio-section desktop relative" v-if="fullscreenoverlay">
      <div id="fixed" class="lamano-gallery-fullscreen-underlay" v-if="fullscreenoverlay">
        <div class="lamano-gallery-fullscreen-overlay">
          <div class="lamano-gallery-description-container" v-if="photoDescriptionText != null">
            <vue-markdown :source="photoDescriptionText" />
          </div>

          <img
            src="@/assets/icons/arrow_downward_black_24dp.svg"
            class="lamano-gallery-navigator-button-icon-full left"
            v-on:click="fullscreenPreviousImage(selectedImage)"
          />
          <img :src="selectedImagePath" class="lamano-gallery-fullscreen-image" />

          <img
            src="@/assets/icons/arrow_downward_black_24dp.svg"
            class="lamano-gallery-navigator-button-icon-full right"
            v-on:click="fullscreenNextImage(selectedImage)"
          />
          <div class="lamano-gallery-full-close" v-on:click="closeGallery()">x</div>

          <div class="lamano-gallery-images-container">
            <img
              :src="photoGalleryPath + photo.attributes.formats.small.url"
              class="lamano-gallery-images-item"
              v-for="photo in photoGallery"
              :key="photo.id"
              @click="selectedImagePath = photoGalleryPath + photo.attributes.url"
            />
          </div>
        </div>
      </div>
    </div>

    <div id="gallery-anchor" class="lamano-gallery-portfolio-section desktop" v-if="!fullscreenoverlay">
      <div v-for="photo in allPhotos" v-bind:key="photo.id" class="lamano-gallery-image-container" :style="photo.image">
        <div class="lamano-gallery-trigger-area" v-on:click="openImage(photo.imageID)"></div>
        <div
          class="lamano-gallery-navigator-button left noselect"
          v-if="photo.id == 3"
          v-on:click="
            if (currentPage > 0) {
              currentPage--;

              fullscreenoverlay = false;
              selectedImagePath = '';
              getAllPhotos();
            }
          "
        >
          <img src="@/assets/icons/arrow_downward_black_24dp.svg" class="lamano-gallery-navigator-button-icon left" />
        </div>
        <div
          class="lamano-gallery-navigator-button right noselect"
          v-if="photo.id == 5"
          v-on:click="
            if (currentPage < countImages / maxImages - 1) {
              currentPage++;

              fullscreenoverlay = false;
              selectedImagePath = '';
              getAllPhotos();
            }
          "
        >
          <img src="@/assets/icons/arrow_downward_black_24dp.svg" class="lamano-gallery-navigator-button-icon right" />
        </div>
        <!-- v-on:click="ifs(photo.imageID)" -->
        <div class="lamano-gallery-bgimage-container"></div>
      </div>
    </div>

    <ul class="pagination-ul-mobile">
      <li
        v-on:click="
          if (currentPage > 0) {
            currentPage--;

            fullscreenoverlay = false;
            selectedImagePath = '';
            getAllPhotos();
          }
        "
      >
        &lt;
      </li>
      <li>{{ currentPage + 1 + "-" + Math.ceil(countImages / maxImages) }}</li>
      <li
        v-on:click="
          if (currentPage < countImages / maxImages - 1) {
            currentPage++;

            fullscreenoverlay = false;
            selectedImagePath = '';
            getAllPhotos();
          }
        "
      >
        &gt;
      </li>
    </ul>
  </div>
</template>

<script>
import { getCMSText } from "@/lib/TextGetter.js";
import VueMarkdown from "vue-markdown-render";

export default {
  name: "Gallery",
  props: {
    ifs: Function,
  },
  data() {
    return {
      selectedFilter: 0,
      allCategories: [],
      allPhotos: [],
      allCategoriesText: "Show all",
      currentPage: 0,
      maxImages: 9,
      categorytext1: "",
      countImages: 0,
      imageFullscreen: false,
      imageFullscreenSelection: "",
      fullscreenoverlay: false,
      selectedImage: 1,
      selectedImagePath: "",
      photoDescriptionText: "",
      photoGalleryPath: "",
      photoGallery: [],
    };
  },
  components: {
    VueMarkdown,
  },
  async mounted() {
    if (window.innerWidth > 600) {
      this.maxImages = 9;
    } else {
      this.maxImages = 6;
    }

    if (window.matchMedia("(orientation: portrait)").matches) {
      this.maxImages = 6;
    }
    this.categorytext1 = await getCMSText("categorytext1");

    await this.getAllCategories();
    await this.getAllPhotos();
  },
  methods: {
    closeGallery() {
      this.fullscreenoverlay = false;
      this.selectedImagePath = "";

      // todo: gallery doesnt exist yet
      this.waitForElm(".lamano-gallery-portfolio-section").then((elm) => {
        document.getElementById("gallery-anchor").scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    waitForElm(selector) {
      return new Promise((resolve) => {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver((mutations) => {
          if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
          }
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
      });
    },
    changeImage(imagePath) {},
    openImage(imageID) {
      if (window.innerWidth > 1) {
        this.fullscreenoverlay = true;
        this.selectedImage = imageID;
        console.log(imageID);

        let headers = {
          Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
        };

        this.axios
          .get(
            process.env.VUE_APP_CMSPROTOCOL +
              "://" +
              process.env.VUE_APP_CMSSERVER +
              ":" +
              process.env.VUE_APP_CMSPORT +
              "/api/photos/" +
              imageID +
              "?populate=*",
            { headers }
          )
          .then((result) => {
            if (result.data.data != null) {
              console.log("found");
              var currentImageFormats = result.data.data.attributes.Image.data.attributes.formats;

              var imagePath = "";
              if (currentImageFormats.large != undefined) {
                imagePath = currentImageFormats.large.url;
              } else if (currentImageFormats.medium != undefined) {
                imagePath = currentImageFormats.medium.url;
              } else if (currentImageFormats.small != undefined) {
                imagePath = currentImageFormats.small.url;
              } else if (currentImageFormats.thumbnail != undefined) {
                imagePath = currentImageFormats.thumbnail.url;
              }

              this.photoDescriptionText = result.data.data.attributes.Description;
              this.photoGallery = result.data.data.attributes.Gallery.data;

              console.log(result.data.data.attributes.Gallery.data);

              this.selectedImagePath =
                process.env.VUE_APP_CMSPROTOCOL + "://" + process.env.VUE_APP_CMSSERVER + ":" + process.env.VUE_APP_CMSPORT + imagePath;

              this.photoGalleryPath =
                process.env.VUE_APP_CMSPROTOCOL + "://" + process.env.VUE_APP_CMSSERVER + ":" + process.env.VUE_APP_CMSPORT;
            }
          });

        document.getElementById("lamano-home-portfolio").scrollIntoView({
          behavior: "smooth",
        });
      }
    },

    async fullscreenNextImage(currentID) {
      let headers = {
        Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
      };
      var result = await this.axios.get(
        process.env.VUE_APP_CMSPROTOCOL +
          "://" +
          process.env.VUE_APP_CMSSERVER +
          ":" +
          process.env.VUE_APP_CMSPORT +
          "/api/photos/?sort[0]=id%3Adesc&pagination[limit]=-1&populate=ElementType",
        { headers }
      );

      var ids = [];
      for (var i = 0; i < result.data.data.length; i++) {
        var et = result.data.data[i].attributes.ElementType.data.id;

        var ets = result.data.data[i].attributes.ElementType.data;
        var etss = [];
        for (var etsj = 0; etsj < ets.length; etsj++) {
          etss.push(ets[etsj].id);
        }

        if (this.selectedFilter == 0 || etss.includes(this.selectedFilter)) {
          ids.push(result.data.data[i].id);
        }
      }

      if (ids.indexOf(currentID) < ids.length - 1) {
        let index = ids.indexOf(currentID);
        this.openImage(ids[index + 1]);
      }
    },

    // todo: choose correct list / rings / earrings / etc.

    async fullscreenPreviousImage(currentID) {
      let headers = {
        Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
      };
      var result = await this.axios.get(
        process.env.VUE_APP_CMSPROTOCOL +
          "://" +
          process.env.VUE_APP_CMSSERVER +
          ":" +
          process.env.VUE_APP_CMSPORT +
          "/api/photos/?sort[0]=id%3Adesc&pagination[limit]=-1&populate=ElementType",
        { headers }
      );

      var ids = [];
      for (var i = 0; i < result.data.data.length; i++) {
        var et = result.data.data[i].attributes.ElementType.data.id;

        var ets = result.data.data[i].attributes.ElementType.data;
        var etss = [];
        for (var etsj = 0; etsj < ets.length; etsj++) {
          etss.push(ets[etsj].id);
        }

        if (this.selectedFilter == 0 || etss.includes(this.selectedFilter)) {
          ids.push(result.data.data[i].id);
        }
      }

      if (ids.indexOf(currentID) > 0) {
        //console.log("change to " + ids[index + 1]);
        let index = ids.indexOf(currentID);
        this.openImage(ids[index - 1]);
      }
    },

    selectFilter(filter) {
      this.currentPage = 0;
      this.selectedFilter = filter;
      this.getAllPhotos();

      this.fullscreenoverlay = false;
      this.selectedImagePath = "";

      if (window.innerWidth >= 1200) {
        document.getElementById("lamano-home-portfolio").scrollIntoView({
          behavior: "smooth",
        });
      } else {
        document.getElementById("gallery-anchor").scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    async getAllCategories() {
      let headers = {
        Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
      };

      const result = await this.axios.get(
        process.env.VUE_APP_CMSPROTOCOL +
          "://" +
          process.env.VUE_APP_CMSSERVER +
          ":" +
          process.env.VUE_APP_CMSPORT +
          "/api/element-types/?sort=SortPosition&populate=*",
        { headers }
      );

      const lang = window.location.pathname.replace(/^\/([^\/]+).*/i, "$1");
      if (lang == "fr") {
        this.allCategoriesText = "Afficher tout";
      } else {
        this.allCategoriesText = "Show all";
      }

      if (result.data.data.length > 0) {
        this.allCategories = [];
        const res = result.data.data;
        for (let i = 0; i < res.length; i++) {
          const currentRes = res[i];
          const currentID = currentRes.id;

          var currentName = currentRes.attributes.Type;
          if (lang == "fr") {
            currentName = currentRes.attributes.localizations.data[0].attributes.Type;
          }

          const currentCategory = {
            id: currentID,
            categoryName: currentName,
          };
          this.allCategories.push(currentCategory);
        }
      } else {
        console.log("No categories found");
      }
    },
    async getAllPhotos() {
      let headers = {
        Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
      };

      let filter = "";
      if (this.selectedFilter != 0) {
        filter = "&filters[ElementType]=" + this.selectedFilter;
      }

      let pageination = "&pagination[page]=" + (this.currentPage + 1) + "&pagination[pageSize]=" + this.maxImages;

      this.axios
        .get(
          process.env.VUE_APP_CMSPROTOCOL +
            "://" +
            process.env.VUE_APP_CMSSERVER +
            ":" +
            process.env.VUE_APP_CMSPORT +
            "/api/photos/?sort[0]=id%3Adesc&populate=*" +
            filter +
            pageination,
          { headers }
        )
        .then((result) => {
          if (result.data.data.length > 0) {
            this.countImages = result.data.meta.pagination.total;

            this.allPhotos = [];
            const res = result.data.data;
            for (let i = 0; i < res.length; i++) {
              const currentRes = res[i].attributes;

              const currentTypeID = currentRes.ElementType.data.id;
              const currentImageFormats = currentRes.Image.data.attributes.formats;
              const currentImageID = res[i].id;

              var imagePath = "";
              if (currentImageFormats.large != undefined) {
                imagePath = currentImageFormats.large.url;
              } else if (currentImageFormats.medium != undefined) {
                imagePath = currentImageFormats.medium.url;
              } else if (currentImageFormats.small != undefined) {
                imagePath = currentImageFormats.small.url;
              } else if (currentImageFormats.thumbnail != undefined) {
                imagePath = currentImageFormats.thumbnail.url;
              }

              const fullImagePath =
                process.env.VUE_APP_CMSPROTOCOL + "://" + process.env.VUE_APP_CMSSERVER + ":" + process.env.VUE_APP_CMSPORT + imagePath;
              const bgStyle = "background-image: url('" + fullImagePath + "');";

              const currentImage = {
                id: this.allPhotos.length,
                typeID: currentTypeID,
                image: bgStyle,
                imageID: currentImageID,
              };
              this.allPhotos.push(currentImage);
            }
          } else {
            this.allPhotos = [];
            console.log("No photos found");
          }
        });
    },
  },
};
</script>

<style>
.gallery-fullscreen-container {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: red;
}

.lamano-gallery-container {
  min-height: 100%;
  background-color: var(--color-grey-1);

  position: relative;
  z-index: 10;

  display: flex;
}

.lamano-gallery-filter-section {
  background-color: var(--color-grey-5);
  width: 20vw;

  position: relative;
}

.lamano-gallery-filter-section {
  display: flex;
  flex-direction: column;

  padding: 0 0 0 2vw;

  align-items: flex-start;
  justify-content: center;
}

.lamano-gallery-filter-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  width: 100%;

  position: relative;
  z-index: 1000;
}

.lamano-gallery-filter-list li {
  margin: 0.5em 0 0.5em 0;
  padding: 1.5em 2em 1.5em 2em;

  color: var(--color-grey-3);

  background-color: var(--color-grey-2);

  font-family: "Montserrat", serif;
  font-size: 12pt;
  font-weight: 400;
}

.lamano-gallery-filter-list li:hover {
  cursor: pointer;
}

.lamano-gallery-filter-list li.selected {
  background-color: var(--color-grey-1);
  color: var(--color-grey-3);
}

.lamano-gallery-filter-list li:hover {
  background-color: var(--color-grey-1);
  color: var(--color-grey-3);
}

.lamano-gallery-portfolio-section {
  background-color: var(--color-grey-1);
  flex-grow: 1;

  display: flex;
  flex-wrap: wrap;

  align-items: flex-start;
  align-content: flex-start;
}

.lamano-gallery-image-container {
  flex-basis: 33%;

  height: 33.33vh;

  display: flex;
  align-content: center;
  justify-content: center;

  background-position: center;
  background-size: cover;

  transition: all 200ms;
  z-index: 800;

  filter: grayscale(100%);
  background-color: #202020;

  position: relative;
}

:root {
  --gallery-border-size: 6px;
  /* desktop 6px*/
  --gallery-border-mobile-size: 2px;
  /* desktop 6px*/
}

.lamano-gallery-image-container::before {
  content: "";
  width: 100%;
  height: 100%;

  z-index: 900;

  background-color: #28282899;
}

.lamano-gallery-image-container:nth-child(5)::before {
  content: "";
  width: 100%;
  height: calc(100% - 2 * var(--gallery-border-size)) !important;
  border: var(--gallery-border-size) solid var(--color-grey-1);
}

.lamano-gallery-image-container:nth-child(2)::before,
.lamano-gallery-image-container:nth-child(8)::before {
  content: "";
  width: 100%;
  height: 100%;
  border-left: var(--gallery-border-size) solid var(--color-grey-1);
  border-right: var(--gallery-border-size) solid var(--color-grey-1);
}

.lamano-gallery-image-container:nth-child(4)::before,
.lamano-gallery-image-container:nth-child(6)::before {
  content: "";
  width: 100%;
  height: calc(100% - 2 * var(--gallery-border-size)) !important;
  border-top: var(--gallery-border-size) solid var(--color-grey-1);
  border-bottom: var(--gallery-border-size) solid var(--color-grey-1);
}

.lamano-gallery-image-container:hover {
  filter: grayscale(0%);
  z-index: 60;
  cursor: pointer;
}

.lamano-gallery-image-container:hover::before {
  content: "";
  width: 100%;
  height: 100%;

  background-color: #28282800;
}

.lamano-gallery-title {
  color: var(--color-gold-1);

  font-size: 32pt;
  font-weight: 300;

  font-family: "Lora", sans-serif;

  margin: 0 0 1vh 0;
  padding: 0;
}

@media only screen and (max-width: 600px), (orientation: portrait) {
  .lamano-gallery-image-container::before {
    /*border: none !important;*/
  }

  .lamano-gallery-image-container:nth-child(1)::before,
  .lamano-gallery-image-container:nth-child(3)::before,
  .lamano-gallery-image-container:nth-child(5)::before,
  .lamano-gallery-image-container:nth-child(7)::before {
    content: "";
    width: 100%;
    height: 100% !important;
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: var(--gallery-border-mobile-size) solid var(--color-grey-1);
  }

  .lamano-gallery-image-container:nth-child(2)::before,
  .lamano-gallery-image-container:nth-child(4)::before,
  .lamano-gallery-image-container:nth-child(6)::before,
  .lamano-gallery-image-container:nth-child(8)::before {
    content: "";
    width: 100%;
    height: 100% !important;
    border-top: none;
    border-bottom: none;
    border-right: none;
    border-left: var(--gallery-border-mobile-size) solid var(--color-grey-1);
  }

  .lamano-gallery-image-container {
    content: "";
    width: 100%;
    height: 100%;
    border-bottom: calc(2 * var(--gallery-border-mobile-size)) solid var(--color-grey-1);
  }

  .lamano-gallery-image-container:nth-child(5),
  .lamano-gallery-image-container:nth-child(6) {
    content: "";
    width: 100%;
    height: 100%;
    border-bottom: none;
  }

  .lamano-gallery-container {
    flex-direction: column;
  }

  .lamano-gallery-filter-section {
    width: 100vw;
  }

  .lamano-gallery-title {
    margin: 4vh 0 4vh 4vh;
  }

  .lamano-gallery-image-container {
    flex-basis: 50%;
    height: calc(var(--vh, 1vh) * 29.5) !important;
  }

  .lamano-gallery-filter-list li {
    padding: 2em 0 2em 2em;
    z-index: 1000;
    position: relative;
  }

  .lamano-gallery-image-container {
    filter: grayscale(0%);
    z-index: 60;
  }

  .lamano-gallery-image-container::before {
    background-color: transparent;
  }

  .pagination-ul {
    position: relative !important;

    left: 0 !important;
    bottom: 0 !important;

    padding-bottom: 0.5em !important;

    width: 100%;
  }

  .pagination-ul-mobile {
    position: relative !important;

    left: 0 !important;
    bottom: 0 !important;

    padding-bottom: 0.5em !important;

    width: 100%;
  }

  ul h2 {
    margin: 4vh 0 4vh 4vh !important;
    display: none !important;
  }

  .pagination-ul {
    display: none !important;
  }

  .pagination-ul-mobile {
    display: flex !important;
  }
}

@media only screen and (max-width: 1200px) {
  .lamano-gallery-image-container:hover {
    cursor: default !important;
  }

  .pagination-ul {
  }

  .pagination-ul-mobile {
  }

  .lamano-gallery-image-container {
    filter: grayscale(0%);
    z-index: 60;
  }

  .lamano-gallery-image-container::before {
    background-color: transparent;
  }
}

ul h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  font-weight: 400;

  color: var(--color-grey-3);
  margin: 2em 0 1em 0;
}

.pagination-ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;

  right: 0;
  left: 2em;

  bottom: 2em;
}

.pagination-ul li {
  width: auto;

  margin: 0;
  padding: 0;

  padding: 1.5em 1em 1.5em 1em;
  text-align: center;
  list-style-type: none;
}

.pagination-ul li:nth-child(1) {
  flex: 1;
  flex-grow: 1;
}

.pagination-ul li:nth-child(3) {
  flex: 1;
  flex-grow: 1;
}

.pagination-ul li:nth-child(2) {
  background-color: var(--color-grey-1);
  cursor: default;
  flex: 1;
}

.pagination-ul-mobile {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;

  right: 0;
  left: 2em;

  height: 10vh !important;

  bottom: 2em;

  display: none;
}

.pagination-ul-mobile li {
  width: auto;

  margin: 0;
  padding: 0;

  padding: 1.5em 1em 1.5em 1em;
  text-align: center;
  list-style-type: none;

  position: relative;

  z-index: 1000;
}

.pagination-ul-mobile li:nth-child(1) {
  flex: 1;
  flex-grow: 1;
}

.pagination-ul-mobile li:nth-child(3) {
  flex: 1;
  flex-grow: 1;
}

.pagination-ul-mobile li:nth-child(2) {
  background-color: var(--color-grey-1);
  cursor: default;
  flex: 1;
}

.pagination-ul-mobile li {
  margin: 0.5em 0 0.5em 0;
  padding: 1.5em 2em 1.5em 2em;

  color: var(--color-grey-3);

  background-color: var(--color-grey-2);

  font-family: "Montserrat", serif;
  font-size: 12pt;
  font-weight: 400;
}

.pagination-ul-mobile li:hover {
  cursor: pointer;
}

.pagination-ul-mobile li.selected {
  background-color: var(--color-grey-1);
  color: var(--color-grey-3);
}

.pagination-ul-mobile li:hover {
  background-color: var(--color-grey-1);
  color: var(--color-grey-3);
}

.lamano-gallery-portfolio-section:hover .lamano-gallery-navigator-button:hover {
  /*width: 40%;*/
  opacity: 0.95;

  cursor: pointer;
}

.lamano-gallery-navigator-button-icon.left {
  transform: rotateZ(90deg);
}

.lamano-gallery-navigator-button-icon.right {
  transform: rotateZ(-90deg);
}

.lamano-gallery-navigator-button-icon-full.left {
  transform: rotateZ(90deg);
  left: 5vw;
}

.lamano-gallery-navigator-button-icon-full.right {
  transform: rotateZ(-90deg);
  right: 5vw;
}

.lamano-gallery-navigator-button-icon-full {
  height: 2vh;
  width: 2vh;

  position: absolute;

  top: 50%;

  transform: translateY(-50%);

  z-index: 50;

  background-color: white;
  opacity: 0.55;

  transition: all 250ms;

  color: var(--color-grey-1);
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  font-weight: 400;

  display: flex;

  align-items: center;
  justify-content: center;

  border-radius: 6vh;

  padding: 1vw;
}

.lamano-gallery-navigator-button-icon-full:hover {
  opacity: 1;
  cursor: pointer;
}

.lamano-gallery-navigator-button {
  height: 6vh;
  width: 6vh;

  position: absolute;

  top: 50%;

  transform: translateY(-50%);

  z-index: 999;

  background-color: #191919;
  opacity: 0.75;

  transition: all 250ms;

  color: var(--color-grey-3);
  font-family: "Montserrat", sans-serif;
  font-size: 18pt;
  font-weight: 400;

  display: flex;

  align-items: center;
  justify-content: center;

  border-radius: 6vh;
}

.lamano-gallery-navigator-button:hover {
  opacity: 0.85;
}

.lamano-gallery-navigator-button.left {
  left: 1vw;
}

.lamano-gallery-navigator-button.right {
  right: 1vw;
}

.lamano-gallery-bgimage-container {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 10;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.lamano-gallery-fullscreen-underlay {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: var(--color-grey-1);

  z-index: 2000;
}

.lamano-gallery-fullscreen-overlay {
  position: absolute;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lamano-gallery-fullscreen-image {
  height: 70vh;

  position: absolute;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.lamano-gallery-full-close {
  position: absolute;
  right: 0;
  top: 0;

  height: 3vw;
  width: 3vw;

  display: flex;

  background-color: #191919;

  font-family: "Montserrat", serif;
  font-size: 18pt;
  font-weight: 400;

  color: var(--color-grey-3);

  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.lamano-gallery-portfolio-section.relative {
  position: relative;
}

.lamano-gallery-trigger-area {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 900;
}

.lamano-gallery-description-container {
  height: 15vh;
  color: var(--color-grey-3);

  font-family: "Montserrat", serif;
  font-size: 12pt;
  font-weight: 400;

  display: flex;
  align-items: center;
  justify-content: center;
}

.lamano-gallery-images-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  height: 12.5vh;

  padding: 0 0 0 0;

  overflow-x: auto;
  white-space: nowrap;

  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.lamano-gallery-images-item {
  height: 100%;
  padding: 2.5vh 0 2.5vh 2.5vh;
}

.lamano-gallery-images-item:hover {
  cursor: pointer;
}

.lamano-gallery-images-item:last-child {
  padding: 2.5vh;
}

@media only screen and (max-width: 600px), (orientation: portrait) {
  .lamano-gallery-container {
    min-height: 100vh;
  }

  .lamano-gallery-fullscreen-underlay {
    position: fixed;

    z-index: 1100;

    height: 100vh;
  }

  .lamano-gallery-fullscreen-image {
    position: fixed;
    height: 50vh;
  }

  .lamano-gallery-navigator-button {
    display: none;
  }

  .lamano-gallery-navigator-button-icon-full {
    background-color: white;
    height: 4vh;
    width: 4vh;
  }

  .lamano-gallery-full-close {
    position: fixed;
    height: 8vh;
    width: 8vh;
    left: 3vw;
    top: 1.75vw;

    background-color: transparent;
    font-weight: 500;
    font-size: 26pt;
  }

  .lamano-gallery-description-container {
    height: 25vh;
  }

  .lamano-gallery-images-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    height: 25vh;

    padding: 0 0 0 0;

    overflow-x: auto;
    white-space: nowrap;

    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .lamano-gallery-images-item {
    height: 12vh;
    padding: 2.5vh 0 2.5vh 2.5vh;
  }

  .lamano-gallery-images-item:last-child {
    padding: 2.5vh;
  }
}

@media only screen and (max-width: 1200px) {
  .lamano-gallery-images-container {
    justify-content: flex-start;
  }

  .lamano-gallery-navigator-button-icon-full {
    background-color: white;
    height: 2.5vh;
    width: 2.5vh;
  }
}

@media only screen and (max-width: 600px) {
  .lamano-gallery-navigator-button-icon-full {
    background-color: white;
    height: 4vh;
    width: 4vh;
  }
}

.lamano-gallery-description-container p {
  margin: 4pt 0 4pt 0;
  padding: 0 2.5em 0 2.5em;
}

#gallery-anchor {
  z-index: 900;
}
</style>

<!-- Contact scroll & iPhone Animatioun-->
