import axios from "axios";

let cmsServer = process.env.VUE_APP_CMSSERVER;
let cmsPort = process.env.VUE_APP_CMSPORT;
let cmsProtocol = process.env.VUE_APP_CMSPROTOCOL;

function getCurrentLanguage() {
  return window.location.pathname.replace(/^\/([^\/]+).*/i, "$1");
}

async function getCMSText(uniqueID) {
  let headers = {
    Authorization: "Bearer " + process.env.VUE_APP_TOKEN
  };
  const lang = getCurrentLanguage();
  const result = await axios.get(
    cmsProtocol + "://" + cmsServer + ":" + cmsPort + "/api/texts?locale=" + lang + "&filters[TextID]=" + uniqueID,
    { headers }
  );

  if (result.data.data.length > 0) {
    return result.data.data[0].attributes.TextField;
  } else {
    return "Undefined in CMS";
  }
}

export { getCMSText };
