<template>
  <div class="lamano-screen-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ScreenContainer"
};
</script>

<style>
.lamano-screen-container {
  height: 100%;
  border-bottom: 12px solid var(--color-grey-1);
}

.lamano-screen-container:last-child {
  border: none !important;
}

@media only screen and (max-width: 600px) {
  .lamano-screen-container {
    height: auto;
    width: 100vw;
  }

  .lamano-screen-container:nth-child(2) {
    border: none !important;
  }
}
</style>
