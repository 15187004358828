<template>
  <div class="lamano-home" :class="{ imageFullscreen: imageFullscreen }">
    <transition name="fade">
      <div class="lamano-loading" v-show="loading">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </transition>

    <div class="lamano-navigation">
      <navigation-bar></navigation-bar>
    </div>
    <main>
      <div class="hideifmobile">
        <screen-container>
          <div id="lamano-home-welcome">
            <video playsinline autoplay muted loop poster="@/assets/overlays/yann-1.jpg" id="bgvid" v-show="!isMobile">
              <source src="@/assets/videos/yannbgvideo-small.mp4" type="video/mp4" />
            </video>
            <div class="lamano-welcome-parallax parallax bg0 overlay">
              <!--<h2 class="lamano-home-parallax-title">{{ sitetitle1 }}</h2>-->
              <img src="@/assets/logo-gold.svg" class="lamano-welcome-logo" />
              <img src="@/assets/icons/arrow_downward_black_24dp.svg" class="lamano-welcome-icon"
                @click="scroll('lamano-home-portfolio')" />
            </div>
          </div>
        </screen-container>
      </div>

      <screen-container>
        <div id="lamano-home-portfolio">
          <gallery :ifs="imageFullscreenFunc"></gallery>
        </div>
      </screen-container>

      <screen-container>
        <div id="lamano-home-introduction">
          <div class="lamano-home-introduction-person left"></div>
          <div class="lamano-home-introduction-person right">
            <h2 class="lamano-home-title">II. {{ categorytext2 }}</h2>
            <h3 class="lamano-home-subtitle">{{ sitetitle2 }}</h3>
            <div class="lamano-home-paragraph">
              <p>{{ mainText1 }}</p>
              <p>{{ mainText2 }}</p>
              <p>{{ mainText3 }}</p>
            </div>
          </div>
        </div>
      </screen-container>

      <screen-container>
        <div id="lamano-home-handcraft">
          <div class="lamano-home-handcraft-section left">
            <h3 class="lamano-home-subtitle-handcraft">{{ sitetitle3 }}</h3>
            <div class="lamano-home-paragraph-handcraft">
              <p>{{ hcText1 }}</p>
              <p>{{ hcText2 }}</p>
              <p>{{ hcText3 }}</p>

              <img src="@/assets/madeinlux.png" class="lamano-madeinlux-large" />
            </div>
          </div>
          <div class="lamano-home-handcraft-section right"></div>
        </div>
      </screen-container>

      <div class="lamano-handcraft-mobile-image"></div>

      <div class="lamano-site-image">
        <div class="lamano-site-image-item"></div>
        <div class="lamano-site-image-item"></div>
        <div class="lamano-site-image-item"></div>
      </div>

      <screen-container>
        <div id="lamano-home-contact">
          <div class="lamano-contact-parallax parallax bgc contactoverlay">
            <ContactForm></ContactForm>
          </div>
        </div>
      </screen-container>
    </main>
  </div>

  <!--
  <div class="lamano-fullscreen-container">
    <div class="lamano-fullscreen-sidebar">
      <ul>
        <li>Lorem Ipsum</li>
        <li>Dolor Sit Amet</li>
      </ul>
    </div>
    <div class="lamano-fullscreen-closebtn" v-on:click="imageFullscreen = false">X</div>

    <div class="lamano-fullscreen-main-container">
      <div class="lamano-fullscreen-image-container" :style="imageFullscreenImage">
        <div class="lamano-fullscreen-navigation-button">&lt;</div>
        <div class="lamano-fullscreen-navigation-button">&gt;</div>
      </div>
      <div class="lamano-fullscreen-gallery-container">gallery</div>
    </div>
  </div>
  -->
</template>

<script>
// @ is an alias to /src

import NavigationBar from "@/components/NavigationBar.vue";
import ScreenContainer from "@/components/ScreenContainer.vue";
import Gallery from "@/components/Gallery.vue";
import ContactForm from "@/components/ContactForm.vue";

import { getCMSText } from "@/lib/TextGetter.js";

export default {
  name: "Home",
  components: {
    NavigationBar,
    ScreenContainer,
    Gallery,
    ContactForm
  },
  data() {
    return {
      loading: true,
      mainText1: "",
      mainText2: "",
      mainText3: "",
      hcText1: "",
      hcText2: "",
      hcText3: "",
      sitetitle1: "",
      sitetitle2: "",
      sitetitle3: "",
      categorytext2: "",
      isMobile: false,
      imageFullscreen: false,
      imageFullscreenImage: null
    };
  },
  async mounted() {
    if (window.innerWidth < 600) {
      this.isMobile = true;
    }

    this.mainText1 = await getCMSText("maintext1");
    this.mainText2 = await getCMSText("maintext2");
    this.mainText3 = await getCMSText("maintext3");

    this.hcText1 = await getCMSText("hctext1");
    this.hcText2 = await getCMSText("hctext2");
    this.hcText3 = await getCMSText("hctext3");

    this.sitetitle1 = await getCMSText("sitetitle1");
    this.sitetitle2 = await getCMSText("sitetitle2");
    this.sitetitle3 = await getCMSText("sitetitle3");
    this.categorytext2 = await getCMSText("categorytext2");

    this.loading = false;

    if (window.location.hash.startsWith("#image-")) {
      this.imageFullscreen = true;
      this.getCurrentFullscreenImage(window.location.hash.substring(7));
    } else {
      this.imageFullscreen = false;
    }
  },
  methods: {
    imageFullscreenFunc: function (image) {
      console.log(image);
      window.location.hash = "image-" + image;
    },
    getCurrentFullscreenImage: function (imageID) {
      let headers = {
        Authorization: "Bearer " + process.env.VUE_APP_TOKEN
      };

      this.axios
        .get(
          process.env.VUE_APP_CMSPROTOCOL +
          "://" +
          process.env.VUE_APP_CMSSERVER +
          ":" +
          process.env.VUE_APP_CMSPORT +
          "/api/photos/" +
          imageID +
          "?populate=*",
          { headers }
        )
        .then(result => {
          const imagePath = result.data.data.attributes.Image.data.attributes.url;

          const fullImagePath =
            process.env.VUE_APP_CMSPROTOCOL + "://" + process.env.VUE_APP_CMSSERVER + ":" + process.env.VUE_APP_CMSPORT + imagePath;
          this.imageFullscreenImage = { backgroundImage: "url(" + fullImagePath + ")" };
        });
    },
    scroll(id) {
      var innerWidth = window.innerWidth;
      var isMobile = innerWidth < 600;

      if (isMobile) {
        this.toggleSidebar();
      }

      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    }
  },
  watch: {
    $route(to, from) {
      console.log("Route from: " + from + " to " + to);
      console.log(to.hash);

      if (to.hash.startsWith("#image-")) {
        this.imageFullscreen = true;
        this.getCurrentFullscreenImage(to.hash.substring(7));
      } else {
        this.imageFullscreen = false;
      }
    }
  }
};
</script>

<style>
.lamano-fullscreen-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: var(--color-grey-1);

  z-index: 0;
}

.lamano-fullscreen-back {
  position: absolute;

  top: 50%;
  left: 0;

  height: 10vh;
  width: 4vw;

  background-color: var(--color-grey-2);
  color: var(--color-grey-3);

  display: flex;
  align-items: center;
  justify-content: center;

  transform: translateY(-50%);
}

.lamano-fullscreen-back:hover {
  cursor: pointer;
}

.imageFullscreen {
  transform: translateX(-100vw);

  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.lamano-home {
  position: relative;
  z-index: 10;
  display: flex;
  height: 100%;

  transition: transform 500ms;
}

main {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 2px;
}

#lamano-home-introduction {
  height: 100%;
  overflow: none;

  background-color: var(--color-grey-2);
}

#lamano-home-portfolio {
  height: 100vh;
}

@media only screen and (max-width: 600px),
(orientation: portrait) {
  #lamano-home-portfolio {
    height: initial;
    min-height: 100%;
  }
}

.lamano-handcraft-mobile-image {
  display: none;
}

.lamano-home-introduction {
  height: 100%;

  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  background-image: url("@/assets/backgrounds/introduction.jpg");
  background-position: center;
  background-size: cover;
}

.lamano-home-introduction-title-upper {
  margin: 0 0 0 5vw;
  padding: 0;

  color: var(--color-gold-1);

  font-family: "Lora", serif;
  font-size: 32pt;
  font-weight: 300;
}

.lamano-home-introduction-title-bottom {
  margin: 0 0 0 5vw;
  padding: 0;

  color: var(--color-gold-1);

  font-family: "Lora", serif;
  font-size: 24pt;
  font-weight: 500;
}

#lamano-home-introduction {
  display: flex;
  height: 100%;
}

.lamano-home-introduction-person {
  flex: 1;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}

.lamano-home-introduction-person.left {
  flex: 1;
  background-image: url("@/assets/profile.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.lamano-home-introduction-person.right {
  flex: 1;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
}

.lamano-home-title {
  color: var(--color-gold-1);

  font-size: 32pt;
  font-weight: 300;

  font-family: "Lora", sans-serif;

  margin: 0;
  padding: 0;
}

.lamano-home-second-title {
  color: var(--color-gold-1);

  font-size: 22pt;
  font-weight: 300;

  font-family: "Lora", sans-serif;

  margin: 2em 0 0 0;
  padding: 0;
  text-align: left;
}

.lamano-home-subtitle {
  color: var(--color-grey-3);

  font-size: 18pt;
  font-weight: 300;

  font-family: "Lora", sans-serif;

  margin: 0;
  padding: 0;
}

.lamano-home-paragraph {
  color: var(--color-grey-3);

  font-size: 12pt;
  font-weight: 400;

  font-family: "Montserrat", sans-serif;

  margin: 1em 0 0 0;
  padding: 0;

  width: 70%;
}

#lamano-home-introduction-second {
  display: flex;

  flex-direction: row;
  align-items: center;
  padding-top: 20em;
  padding-bottom: 20em;

  background-color: var(--color-grey-2);
}

.lamano-home-introduction-second-container {
  flex: 1;

  flex-direction: column;

  display: flex;
  align-items: left;
  justify-content: center;

  padding: 5em;
}

.lamano-home-introduction-second-container.left {
  padding-right: 2em;
}

.lamano-home-second-paragraph {
  color: var(--color-grey-3);

  font-size: 12pt;
  font-weight: 400;

  font-family: "Montserrat", sans-serif;

  margin: 1em 0 0 0;
  padding: 0;

  text-align: left;
}

.lamano-home-introduction-second-image {
  width: 100%;
}

.lamano-home-parallax-container {
  height: 50vh;
  overflow: hidden;
}

.lamano-welcome-parallax {
  position: relative;
  width: 100%;
  height: 100vh;

  flex-direction: column;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.lamano-contact-parallax {
  position: relative;
  width: 100%;
  height: 100vh;

  flex-direction: column;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.lamano-home-parallax {
  position: relative;
  width: 100%;
  height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

#bgvid {
  position: absolute;
  width: 100%;
  height: 100vh;

  z-index: 0;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.parallax::after {
  /* Display and position the pseudo-element */
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px) scale(2);
  z-index: -1;
}

.bg0::after {
  background-image: url("@/assets/overlays/yann-1.jpg");
  background-size: cover;
  background-position: center;
}

.bg1::after {
  background-image: url("@/assets/overlays/yann-2.jpg");
  background-size: cover;
  background-position: center;
}

.bg2::after {
  background-image: url("@/assets/overlays/yann-3.jpg");
  background-size: cover;
  background-position: center;
}

.bg3::after {
  background-image: url("@/assets/overlays/yann-4.jpg");
  background-size: cover;
  background-position: center;
}

.bgc::after {
  background-image: url("@/assets/overlays/yann-2.jpg");
  background-size: cover;
  background-position: center;
}

.overlay {
  background-color: #28282899;
}

.lamano-home-parallax-title {
  color: var(--color-gold-1);

  font-size: 48pt;
  font-weight: 400;

  font-family: "Lora", serif;

  margin: 0 0 0 0;
  padding: 0;

  text-align: center;

  width: 70%;
}

.lamano-home-introduction-second-container:nth-child(1) {
  background-image: url("@/assets/icons/design_services_black_24dp.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.lamano-home-introduction-second-container:nth-child(2) {
  background-image: url("@/assets/icons/park_black_24dp.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.lamano-home-introduction-second-container:nth-child(3) {
  background-image: url("@/assets/icons/category_black_24dp.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.lamano-welcome-logo {
  width: 8vw;
  margin-bottom: 4em;

  padding: 1em;

  position: absolute;
}

.lamano-welcome-icon {
  width: 2em;
  margin-bottom: 4em;

  background-color: var(--color-grey-1-trans);
  padding: 1em;
  border-radius: 2em;

  position: absolute;
  bottom: 0;

  transition: background 250ms;
}

.lamano-welcome-icon:hover {
  cursor: pointer;
  background-color: var(--color-grey-1);
}

#lamano-home-contact {
  height: 100vh;
  overflow: hidden;
}

.contactoverlay {
  background-color: #28282899;
}

.lamano-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-grey-2);

  z-index: 750;
}

.lds-ellipsis {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;

  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

#lamano-home-handcraft {
  display: flex;
  height: 100%;
  width: 100%;
}

.lamano-home-handcraft-section {
  flex: 1;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}

.lamano-home-handcraft-section.left {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  background-color: var(--color-grey-5);
}

.lamano-home-handcraft-section.right {
  background-image: url("@/assets/backgrounds/handcraft.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.lamano-home-paragraph-handcraft {
  color: var(--color-grey-3);

  font-size: 12pt;
  font-weight: 400;

  font-family: "Montserrat", sans-serif;

  margin: 1em 0 0 15%;
  padding: 0;

  width: 70%;
}

.lamano-home-subtitle-handcraft {
  color: var(--color-grey-3);

  font-size: 18pt;
  font-weight: 300;

  font-family: "Lora", sans-serif;

  margin: 0 0 0 15%;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  #lamano-home-introduction {
    flex-direction: row;
    position: relative;
  }

  .lamano-home-introduction-person.left {
    min-height: 100%;
    width: 150vw;
    position: absolute;
    top: 0;

    z-index: 0;

    display: none;

    transform: translateX(-50%);
  }

  .hideifmobile {
    display: none;
  }

  .lamano-home-introduction-person {
    flex-direction: column;
  }

  .lamano-home-introduction-person.right {
    align-content: flex-start;
    align-items: flex-start;
  }

  #lamano-home-handcraft {
    flex-direction: column-reverse;
  }

  .lamano-home-handcraft-section.left {
    align-items: flex-start;

    padding: 4em 1em 4em 1em;

    border-top: 12px solid var(--color-grey-1);
  }

  .lamano-home-introduction-person.right {
    padding: 4em 2em 4em 2em;
  }

  .lamano-home-subtitle-handcraft {
    margin: 0;
  }

  .lamano-home-paragraph-handcraft {
    margin: 0;
  }

  .lamano-home-handcraft-section.right {
    min-height: 50vh !important;
  }

  #lamano-home-introduction {
    height: auto;
    width: 100vw;
  }

  .lamano-home-paragraph {
    width: 100%;
  }

  .lamano-home-paragraph-handcraft {
    width: 100%;
  }

  .lamano-contactform-container {
    flex-direction: column;
  }

  .lamano-contactform-flex {
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
  }

  .lamano-contactform-flex:nth-child(2) {
    align-content: center;
    align-items: center;
  }

  .bgc::after {
    background-image: none;
    background-size: cover;
    background-position: center;

    background-color: var(--color-grey-2);
  }

  #lamano-home-contact {
    width: 100vw;
  }

  .lamano-handcraft-mobile-image {
    width: 100vw;
    height: 50vh;

    display: block;

    background-image: url("@/assets/overlays/yann-2.jpg");
    background-position: center;
    background-size: cover;
  }
}

.lamano-fullscreen-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  width: 18vw;

  padding-left: 2vw;

  background-color: var(--color-grey-5);

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.lamano-fullscreen-closebtn {
  position: absolute;
  right: 0;
  top: 0;

  width: 3vw;
  height: 3vw;

  background-color: var(--color-grey-2);

  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Montserrat", serif;
  font-size: 16pt;
  font-weight: 300;

  color: var(--color-grey-3);

  cursor: pointer;
}

.lamano-fullscreen-main-container {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 20vw;
  right: 0;

  display: flex;
  flex-direction: row;
}

.lamano-fullscreen-image-container {
  background-image: url("@/assets/overlays/yann-1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  flex: 3;

  position: relative;
}

.lamano-fullscreen-gallery-container {
  background-color: var(--color-grey-2);
  flex: 1;
}

.lamano-fullscreen-sidebar ul {
  margin: 0;
  padding: 0;

  list-style-type: none;
  width: 100%;
}

.lamano-fullscreen-sidebar ul li {
  margin: 0.5em 0 0.5em 0;
  padding: 1.5em 2em 1.5em 2em;

  color: var(--color-grey-3);

  background-color: var(--color-grey-2);

  font-family: "Montserrat", serif;
  font-size: 12pt;
  font-weight: 400;
}

.lamano-fullscreen-sidebar ul h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  font-weight: 400;

  color: var(--color-grey-3);
  margin: 2em 0 1em 0;
}

.lamano-fullscreen-navigation-button:nth-child(1) {
  position: absolute;

  left: 0;
  top: 50%;

  transform: translateY(-50%);

  width: 5vw;
  height: 20vh;

  background-color: var(--color-grey-1);
  opacity: 0.75;

  display: flex;

  align-items: center;
  justify-content: center;

  font-family: "Lora", serif;
  font-size: 26pt;
  font-weight: 300;
  color: var(--color-grey-3);
}

.lamano-fullscreen-navigation-button:nth-child(2) {
  position: absolute;

  top: 50%;

  transform: translateY(-50%);
  right: 0;

  width: 5vw;
  height: 20vh;

  background-color: var(--color-grey-1);
  opacity: 0.75;

  display: flex;

  align-items: center;
  justify-content: center;

  font-family: "Lora", serif;
  font-size: 26pt;
  font-weight: 300;
  color: var(--color-grey-3);
}

.lamano-site-image {
  height: 50vh;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--color-grey-2);

  border-bottom: 12px solid var(--color-grey-1);
}

.lamano-site-image-item {
  height: 40vh;
  width: 30%;

  margin: 1%;

  display: flex;
}

.lamano-site-image-item:nth-child(1) {
  background-image: url("@/assets/work/workimage1.jpg");
  background-position: center;
  background-size: cover;
}

.lamano-site-image-item:nth-child(2) {
  background-image: url("@/assets/work/workimage2.jpg");
  background-position: center;
  background-size: cover;
}

.lamano-site-image-item:nth-child(3) {
  background-image: url("@/assets/work/workimage3.jpg");
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  .lamano-site-image {
    flex-direction: column;
    height: auto;
  }

  .lamano-site-image-item {
    width: 100%;
    height: 50vh;

    margin: 0;
    border-top: 12px solid var(--color-grey-1);
  }
}

@media only screen and (max-width: 600px),
(orientation: portrait) {
  .hideifmobile {
    display: none;
  }

  .lamano-screen-container:nth-child(2) {
    height: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .lamano-home-paragraph {
    margin: 0 5vw 0 0;
  }

  .lamano-home-paragraph-handcraft {}

  .pagination-ul-mobile {
    overflow: hidden;
  }
}

.lamano-madeinlux-large {
  width: 6em;
  margin-top: 2em;
}</style>
